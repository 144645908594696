import * as React from "react"
import { type HeadFC, type PageProps } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { langProps } from "../components/types"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

// extend pageContext Object with 'lang' attribute
type IndexQueryProps = { }
type LocaleLookUpInfo = { } & { lang: 'de' | 'en' }
type IndexPageProps = PageProps<IndexQueryProps, LocaleLookUpInfo>


export const query = graphql`
query PrivacyPolicyQuery($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/privacy.md/"}
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`

const IndexPage = ({data, pageContext}: any) => {

  data = data.markdownRemark

  return (
    <Layout lang={pageContext.lang}>
      <div className="mainContent terms" dangerouslySetInnerHTML={{__html: data.html}}/>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    return <Headers
      lang={pageContext.lang}
      title={data.markdownRemark.frontmatter.title}
      location={location}
      />
}
